var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    ref: "iframeEl",
    style: _vm.iframeStyle,
    attrs: {
      src: _vm.path,
      frameborder: "0",
      height: _vm.fitHeight,
      allowtransparency: "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }